import React from 'react'
import { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout'
import useGetData from '../../../hooks/useGetData';

import Pagination from '../../../components/paginate/index'
import Moment from 'react-moment';

const getRatingColor = (rating) => {
  if (rating === "Exit") {
    return "bg-red-100 text-red-500 border border-red-500 shadow"
  } else if (rating === "Buy") {
    return "bg-green-50 text-green-500 border border-green-500 shadow"
  } else if (rating === "Hold") {
    return "bg-blue-100 text-blue-500 border border-blue-500 shadow"
  }
}


const OrderByComponent = ({ visible, order }) => {
  return (
    <>
      {
        order === 'desc' ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${visible ? '' : 'hidden hover:block'} ml-1 w-5 h-5`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`${visible ? '' : 'hidden hover:block'} ml-1 w-5 h-5`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
          </svg>
        )
      }
    </>
  )
}

const companyInitialState = {
  limit: 10,
  pageNo: 0,
  searchQuery: '',
  order: '',
  order_method: ''
}

const CompanyMaster = () => {
  const navigate = useNavigate();
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(companyInitialState, 'getCompanyList')

  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
      ...getFormData,
      [e.target.name]: value
    }
    setGetFormData(data)
  }

  const onOrderhandle = (data) => {
    if (getFormData.order === data) {
      if (getFormData.order_method === "desc") {
        setGetFormData({
          ...getFormData,
          order_method: "asc"
        })
      } else if (getFormData.order_method === "asc") {
        setGetFormData({
          ...getFormData,
          order_method: "desc"
        })
      }
    } else {
      setGetFormData({
        ...getFormData,
        order: data,
        order_method: "desc"
      })
    }
  }

  const handlePageClick = (val) => {
    let data = {
      ...getFormData,
      pageNo: val.selected
    }
    setGetFormData(data)
  }

  return (
    <>
      <Layout>
        <div className='flex items-center mb-4 justify-between'>
          <h1 className='font-bold text-start text-xl mb-4'>Company Master</h1>
          {/* <p onClick={() => setIsOpenAddModal(true)} className='h-max w-max rounded-md px-3 py-2 text-dark  bg-light hover:cursor-pointer'>Add</p> */}
          <Link to={'/company-master/add'} >
            <p className='h-max w-max rounded-md px-3 py-2 text-[#518dca] bg-white border-[#518dca] border hover:cursor-pointer'>Add</p>
          </Link>
        </div>
        <div className='mb-4 w-full'>
          <input onChange={handleSearchVal} name="searchQuery" className='w-[40%] border drop-shadow shadow-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-gray-300 rounded-md px-3 py-2' type='text' placeholder="Search stocks..." />
        </div>
        {/* drop-shadow-md */}
        <div className='wrapper overflow-x-auto relative border shadow-sm sm:rounded-lg mb-4'>
          <table className="w-full text-sm text-left text-gray-500 overflow-x-auto">
            <thead className="text-xs  uppercase text-[#518dca]   bg-opacity-100">
            {/* <thead className="text-xs text-[#518dca] uppercase bg-[#518dca]"> */}
              <tr className=' border-b bg-blue-100'>
                <th scope="col" className="sticky-col bg-blue-100 text-[12px] first-col outline outline-1 outline-slate-200 text-[#518dca]   py-2 px-4 text-left">
                  Stock Name
                </th>
                <th scope="col" className="py-3 text-[12px] px-4 text-center -z-10" onClick={() => onOrderhandle("record_date")}>
                  <div className='flex justify-center hover:cursor-pointer'>
                    <span>Reco Date</span>
                    <OrderByComponent visible={getFormData.order === "record_date"} order={getFormData.order_method} />
                  </div>
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]">
                  Reco Price
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]" onClick={() => onOrderhandle("current_price")}>
                  <div className='flex justify-center hover:cursor-pointer'>
                    <span>Current Price </span>
                    <OrderByComponent visible={getFormData.order === "current_price"} order={getFormData.order_method} />
                  </div>
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]" onClick={() => onOrderhandle("current_market_cap")}>
                  <div className='flex justify-center hover:cursor-pointer'>
                    <span>Current Market <br /> Cap(Rs Crs) </span>
                    <OrderByComponent visible={getFormData.order === "current_market_cap"} order={getFormData.order_method} />
                  </div>
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]" onClick={() => onOrderhandle("gain_and_loss")}>
                  <div className='flex justify-center hover:cursor-pointer'>
                    <span>% Gain / Loss </span>
                    <OrderByComponent visible={getFormData.order === "gain_and_loss"} order={getFormData.order_method} />
                  </div>
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]">
                  Rating
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]">
                  Sizing Guide
                </th>
                <th scope="col" className="py-3 px-4 text-center text-[12px]">
                  Industry
                </th>
              </tr>
            </thead>
            <tbody>
              {
                handleGetDataList?.isLoading ? (
                  <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                    <td className="py-4 px-7 text-center" colSpan={9}>
                      loading...
                    </td>
                  </tr>
                ) : (
                  handleGetDataList?.data?.data?.length !== 0 ? (
                    handleGetDataList?.data?.data?.map((c, idx) => {
                      const { id, company_name, rating, sizing_guide, category_name, record_date, reco_price, current_price, current_market_cap, gain_and_loss, short_code } = c;
                      return (
                        <tr className={`${idx % 2 === 0 ? 'bg-white' : 'bg-[#f9f9f9]'} border-b hover:cursor-pointer hover:bg-gray-50`} key={id} onClick={() => navigate("/company-master/" + id)}>
                          <th scope="row" className={`sticky-col text-[14px] first-col outline outline-1 outline-slate-200 ${idx % 2 === 0 ? 'bg-white' : 'bg-[#f9f9f9]'} py-2 px-4 font-medium text-gray-900 whitespace-nowrap text-white`}>
                            <Link to={"/company-master/" + id}>{short_code ? short_code : company_name}</Link>
                          </th>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {/* {record_date} */}
                            <Moment unix format='DD/MM/YYYY' date={record_date}/>
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {reco_price}
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {current_price}
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {parseInt(current_market_cap).toLocaleString("en-US")}
                          </td>
                          <td className={`py-2 px-4 text-center text-[12px] ${parseInt(gain_and_loss) > 0 ? 'text-green-500' : 'text-red-500'}`}>
                            {gain_and_loss}
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            <p className={`px-4 py-1 ${getRatingColor(rating)} rounded-md w-fit text-center m-auto`}>{rating}</p>
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {sizing_guide}
                          </td>
                          <td className="py-2 px-4 text-center text-[12px]">
                            {category_name}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                      <td className="py-4 px-7 text-center" colSpan={9}>
                        No stocks found
                      </td>
                    </tr>
                  )

                )
              }
            </tbody>
          </table>
        </div>
        <div className=" my-7">
          {
            handleGetDataList.isFetched && (
              <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetDataList?.data?.total_count / getFormData.limit)} pageNo={handleGetDataList?.data?.page_no} />
            )
          }
        </div>
      </Layout>
      <Toaster position="top-right" />
      {/* {isOpenAddModal && <AddCompany open={isOpenAddModal} setOpen={setIsOpenAddModal}/>}      */}
    </>
  )
}

export default CompanyMaster