import React from 'react'
import logo from '../../capitalideasnew.png'

const HeroPage = () => {
  return (
    <div className='flex h-full w-full lg:w-1/2  py-12 md:py-8 md:rounded-tl-lg  md:rounded-bl-lg px-4 md:px-12'>
      <div className='h-max w-full my-auto'>
        <img className='lg:w-[300px] lg:scale-150  lg:ml-[6%] mb-6' src={logo} alt='logo' />
        <div className='mt-8'>
          <div className='flex items-center mt-4'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 256 256"
            >
              <path
                fill="#518dca"
                strokeMiterlimit="10"
                strokeWidth="0"
                d="M87.85 41.551L5.545 1.167C2.414-.369-.979 2.725.263 5.984l14.342 37.648a3.836 3.836 0 010 2.735L.263 84.016c-1.241 3.259 2.152 6.353 5.282 4.817L87.85 48.449c2.867-1.406 2.867-5.492 0-6.898z"
                transform="matrix(2.81 0 0 2.81 1.407 1.407)"
              ></path>
            </svg>
            <p className='ml-4 text-lg font-medium'>Independent Equity Research</p>
          </div>
          <div className='flex items-center mt-4'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 256 256"
            >
              <path
                fill="#518dca"
                strokeMiterlimit="10"
                strokeWidth="0"
                d="M87.85 41.551L5.545 1.167C2.414-.369-.979 2.725.263 5.984l14.342 37.648a3.836 3.836 0 010 2.735L.263 84.016c-1.241 3.259 2.152 6.353 5.282 4.817L87.85 48.449c2.867-1.406 2.867-5.492 0-6.898z"
                transform="matrix(2.81 0 0 2.81 1.407 1.407)"
              ></path>
            </svg>
            <p className='ml-4 text-lg font-medium'>Long Term Orientation</p>
          </div>
          <div className='flex items-center mt-4'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 256 256"
            >
              <path
                fill="#518dca"
                strokeMiterlimit="10"
                strokeWidth="0"
                d="M87.85 41.551L5.545 1.167C2.414-.369-.979 2.725.263 5.984l14.342 37.648a3.836 3.836 0 010 2.735L.263 84.016c-1.241 3.259 2.152 6.353 5.282 4.817L87.85 48.449c2.867-1.406 2.867-5.492 0-6.898z"
                transform="matrix(2.81 0 0 2.81 1.407 1.407)"
              ></path>
            </svg>
            <p className='ml-4 text-lg font-medium'>Regular Updates</p>
          </div>
          <div className='flex items-center mt-4'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 256 256"
            >
              <path
                fill="#518dca"
                strokeMiterlimit="10"
                strokeWidth="0"
                d="M87.85 41.551L5.545 1.167C2.414-.369-.979 2.725.263 5.984l14.342 37.648a3.836 3.836 0 010 2.735L.263 84.016c-1.241 3.259 2.152 6.353 5.282 4.817L87.85 48.449c2.867-1.406 2.867-5.492 0-6.898z"
                transform="matrix(2.81 0 0 2.81 1.407 1.407)"
              ></path>
            </svg>
            <p className='ml-4 text-lg font-medium'>Check & compare performance</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HeroPage