import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from "../../capitalideasnew.png";
import Cookies from "universal-cookie";
import { Drawer } from "@mui/material";
import jwtDecode from "jwt-decode";
import useGetData from "../../hooks/useGetData";
import useOutsideClick from "../../hooks/useOutsideClick";
import Footer from "../footer";
import MembershipCountdown from "./MembershipCountdown";
import Badge from "../Badge";
import semiLogo from "../../assets/cap_icon_red.png";
import parse from "html-react-parser";

const locationArray = ["/", "/stocks", "/updates"];
const cookies = new Cookies();
const UserLayout = ({ children }) => {
  // const {subscription_type} = data || {}
  const { pathname } = useLocation();
  // console.log(pathname);
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const [menuVisible, setMenuVisisble] = useState(false);
  let name = cookies.get("_fname");
  let token = cookies.get("_pn_token");

  let subscription = cookies.get("_subscription");
  const toUserProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };
  const toUserOrders = (e) => {
    e.preventDefault();
    navigate("/orders");
  };

  const handleClickOutside = () => {
    setMenuVisisble(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const handleLogout = (e) => {
    cookies.remove("_pn_token", { path: "/" });
    cookies.remove("_name", { path: "/" });
    cookies.remove("_subscription", { path: "/" });
    navigate("/signin");
  };

  const showMenu = () => {
    setMenuVisisble(!menuVisible);
  };

  const initialData = {
    screen: "All",
  };
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(
    initialData,
    "banner"
  );

  return (
    <>
      <MembershipCountdown />
      <div className="h-screen flex flex-col">
        <div className="flex  items-center justify-between h-[4.75rem] w-full relative md:max-w-[1300px] lg:max-w-[1500px] xl:max-w-[1750px] px-4 py-2 md:py-4 lg:py-4 mx-auto xl:px-24 2xl:px-36">
          {/* {<Badge data={subscription} name={name} />} */}
          <div>
            <img
              onClick={() => navigate("/stocks")}
              className="w-[150px]  md:w-[191px] hover:cursor-pointer"
              src={logo}
              alt="logo"
            />{" "}
            <div className="items-center hidden text-center bg-[#fe100e]/20  rounded-md px-[8px] py-[1px] space-x-2 mt-1">
              {/* <img src={semiLogo} alt="semiLogo" className=' w-4 h-4' /> */}
              {subscription?.subscription_type && (
                <p className=" uppercase text-[9px] inline font-semibold">
                  {subscription?.subscription_type}
                </p>
              )}
            </div>
            {/* <Badge data={subscription} name={name}/> */}
            {subscription?.subscription_type && (
              <div className=" flex lg:justify-end ">
                <div className="flex items-center bg-white border-dark rounded-md px-[8px] py-[1.5px] space-x-2 mt-1">
                  {/* <img src={semiLogo} alt="semiLogo" className=' w-4 h-4' /> */}
                  <p className=" uppercase text-[9px]  font-semibold">
                    {subscription?.subscription_type}
                  </p>
                </div>
              </div>
            )}
          </div>
          {token && (
            <div className="flex items-center mb-[-14px] lg:mb-0">
              {/* Desktop View */}
              <p
                className={`hidden md:flex font-semibold mr-4 hover:cursor-pointer navHeading ${
                  pathname === "/" && "active"
                } hover:text-`}
                onClick={() => navigate("/")}
              >
                Home
              </p>
              <p
                className={`hidden md:flex font-semibold mr-4 hover:cursor-pointer navHeading ${
                  pathname === "/stocks" && "active"
                } hover:text-`}
                onClick={() => navigate("/stocks")}
              >
                Dashboard
              </p>
              <p
                className={`hidden md:flex font-semibold mr-4 hover:cursor-pointer navHeading ${
                  pathname === "/updates" && "active"
                } hover:text-`}
                onClick={() => navigate("/updates")}
              >
                Updates
              </p>
              <p
                className="hidden md:flex font-semibold mr-4 hover:cursor-pointer text-dark border-dark px-4 rounded-md py-2"
                onClick={() => navigate("/membership")}
              >
                Membership
              </p>
              <p
                className={`hidden md:flex font-semibold mr-4 hover:cursor-pointer navHeading ${
                  pathname === "/user-guide" && "active"
                } hover:text-`}
                onClick={() => navigate("/user-guide")}
              >
                User Guide
              </p>
              {/* <p onClick={handleLogout} className="hidden font-semibold md:block text-red-500 mr-4 hover:cursor-pointer">Logout</p> */}
              <div className="relative" ref={ref}>
                <div className="relative hover:cursor-pointer">
                  <div onClick={showMenu} className="flex items-center">
                    <p className="hidden font-semibold md:block text-dark hover:cursor-pointer">
                      {name}
                    </p>
                    <div className="w-8 h-8 flex items-center justify-center">
                      <div className="arrow-down text-text-dark hidden md:block"></div>
                    </div>
                  </div>
                </div>
                {/* <Badge ref={ref} handleClickBadge={() => setMenuVisisble(true)} data={subscription} name={name} /> */}
                {/* <div className='flex items-center bg-[#fe100e]/20 rounded-md px-[8px] py-[1.5px] space-x-2 mt-1'> */}
                {/* <img src={semiLogo} alt="semiLogo" className=' w-4 h-4' /> */}
                {/* <p className=' uppercase text-md  font-semibold'>{subscription?.subscription_type}</p> */}
                {/* <p className=' uppercase text-[9px] font-semibold'>{subscription?.subscription_type}</p> */}
                {/* </div> */}
                {menuVisible && (
                  <div className="hidden md:block absolute shadow-lg bg-white p-1 h-max border rounded-lg w-64 right-0 z-20">
                    <div
                      className="font-semibold flex justify-between items-center w-full rounded hover:border-[#518dca] hover:border hover:text-dark py-2 px-3"
                      onClick={toUserProfile}
                    >
                      <p>Profile</p>
                      <svg
                        className="profile_logo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#518dca"
                        height="30"
                        width="30"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      className="font-semibold flex justify-between items-center w-full rounded hover:border-[#518dca] hover:border hover:text-dark py-2 px-3"
                      onClick={toUserOrders}
                    >
                      <p>Orders</p>
                      <svg
                        className="w-6 h-6 text-[#518dca]"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                        />
                      </svg>
                    </div>
                    <div
                      className="font-semibold flex justify-between items-center w-full rounded hover:border-[#518dca] hover:border hover:text-dark py-2 px-3"
                      onClick={handleLogout}
                    >
                      <p>Logout</p>
                      <svg
                        className="profile_logo"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke="#518dca"
                        fill="none"
                        height="25"
                        width="25"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                        />
                      </svg>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg> */}
                    </div>
                  </div>
                )}
              </div>
              {/* Mobile View */}
              <svg
                onClick={() => setDrawer(true)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className=" md:hidden w-6 h-6 mt-[-8px]"
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <Drawer
                anchor={"left"}
                open={drawer}
                PaperProps={{
                  sx: { width: "70%" },
                }}
                onClose={() => setDrawer(false)}
              >
                <div className="flex flex-col h-full  px-6">
                  {/* <svg onClick={() => setDrawer(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 ml-6 mt-8">
                      <path stroke-linecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg> */}
                  <div className="flex items-center my-12">
                    <svg
                      className="profile_logo"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#518dca"
                      height="40"
                      width="40"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="font-semibold text-xl ml-2">{name}</p>
                  </div>
                  <NavLink
                    to={"/"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      Home
                    </p>
                  </NavLink>
                  <NavLink
                    to={"/stocks"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      Dashboard
                    </p>
                  </NavLink>
                  <NavLink
                    to={"/updates"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      Updates
                    </p>
                  </NavLink>
                  <NavLink
                    to={"/user-guide"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      User Guide
                    </p>
                  </NavLink>
                  <NavLink
                    to={"/membership"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      Membership
                    </p>
                  </NavLink>
                  <NavLink
                    to={"/profile"}
                    className={({ isActive }) =>
                      `${
                        isActive ? "text-light underline" : "text-[#00000099]"
                      }`
                    }
                  >
                    <p className="font-semibold w-full text-xl my-3 mr-24">
                      Profile
                    </p>
                  </NavLink>
                  <p
                    className="font-semibold text-xl my-3 mb-12 mr-24 mt-auto text-dark"
                    onClick={handleLogout}
                  >
                    Logout
                  </p>
                </div>
              </Drawer>
            </div>
          )}
        </div>
        <hr />

        {locationArray.includes(pathname) && (
          <>
          
            {handleGetDataList?.data?.data?.content && (
              <div className=" p-4 xl:px-24 2xl:px-36  md:max-w-[1300px]  lg:max-w-[1500px] xl:max-w-[1750px] mx-auto w-full">
                <div className="  rounded  bg-gray-200 text-black p-5">
                  {parse(handleGetDataList?.data?.data?.content)}
                </div>
              </div>
            )}
          </>
        )}
      
        <div className="flex w-full h-max flex-col p-4 xl:px-24 2xl:px-36 py-10  md:max-w-[1300px] lg:max-w-[1500px] xl:max-w-[1750px] mx-auto">
          {children}
        </div>
        {/* <div className="bg-[#f2f3f6]">
          <footer className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 text-sm  mt-12 bottom-0 py-8 px-4 md:px-8 lg:px-[5%] xl:px-[10%] md:max-w-[1300px] lg:max-w-[1500px] xl:max-w-[1750px] mx-auto'>
            <div className='md:flex md:flex-col'>
              <img onClick={() => navigate('/stocks')} className='w-[150px] hover:cursor-pointer mb-4' src={logo} alt='logo' />
              <p className='font-semibold text-lg mb-2'>Hitesh Patel</p>
              <p className='font-semibold text-md mb-2'>SEBI Registered Research Analyst</p>
              <p className='text-md mb-2'>SEBI Registration No: INH000009612</p>
            </div>
            <div className='md:flex md:flex-col md:px-24'>
              <p className='font-semibold text-lg mb-2'>Reach Us</p>
              <p className='text-md mb-2'>info@capitalideaz.in</p>
              <p className='text-md mb-2'>+91 7506622757</p>
            </div>
            <div className='md:flex md:flex-col md:px-24'>
              <p className='font-semibold text-lg mb-2'>More Information</p>
              <p className='text-md mb-2'><a rel="noreferrer" href='https://capitalideaz.in/investor-chart/' target='_blank'>Investor Charter</a></p>
              <p className='text-md mb-2'><a rel="noreferrer" href='https://capitalideaz.in/investor-grievances/' target='_blank'>Investor Grievance</a></p>
              <p className='text-md mb-2'><a rel="noreferrer" href='https://capitalideaz.in/privacy-policy/' target='_blank'>Privacy Policy</a></p>
              <p className='text-md mb-2'><a rel="noreferrer" href='https://capitalideaz.in/disclaimer-terms-condition/' target='_blank'>Disclaimer, Terms & Condition</a></p>
            </div>
          </footer>
        </div>
        <div className="bg-[#fe100e]">
          <footer className='flex flex-col justify-center items-center font-semibold text-sm py-4 text-white  bottom-0 md:max-w-[1300px] lg:max-w-[1500px] xl:max-w-[1750px] mx-auto'>
            <p className='mb-2 text-center'>Copyright @ 2022 Capital Ideas Analytics. All rights reserved. </p>
            <p>Designed and Developed by iDigitize</p>
          </footer>
        </div> */}
        <Footer />
      </div>
    </>
  );
};

export default UserLayout;
