import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-hot-toast';
import FormElement from '../../../components/Form/FormElement';
import useAddData from '../../../hooks/useAddData';

const DeleteGeneral = ({
    open, handleClose,data
}) => {
    const { setDataToServer } = useAddData('/deleteGeneralDocument', () => handleClose(),'getGeneralUploadList')
    const [value, setValue] = useState('')
    const handleFormSubmit = (e) => {
        e.preventDefault();
        let id = data?.id
        if(value === "Delete"){
          setDataToServer.mutate({id})
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} sx={{ minWidth: '25%' }}>
            <form onSubmit={handleFormSubmit}>
                <DialogContent>
                    <h1 className='font-bold text-lg mb-3'>Delete</h1>
                    <p>This action cannot be undone. This will permanently delete the <span className='font-bold'>{data?.title}</span> file</p>
                    <p className='mb-3'>Please enter <span className='font-bold'>Delete</span> to confirm</p>
                    <FormElement
                        value={value}
                        type='text'
                        element='input'
                        onChange={(e) => setValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            color: "#fe100e",
                            ':hover': {
                                bgcolor: "#ffebea"
                            }
                        }} onClick={handleClose}
                    >Cancel</Button>
                    <Button
                        sx={{
                            color: "#fe100e",
                            ':hover': {
                                bgcolor: "#ffebea"
                            }
                        }}
                        type="submit"
                        disabled={value !== "Delete" ? true : false}
                    >Delete</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default DeleteGeneral