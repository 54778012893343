import React, { useState } from 'react'
import Layout from '../../../components/Layout';
import {Toaster} from 'react-hot-toast';
import useAddData from '../../../hooks/useAddData';
import useGetData from '../../../hooks/useGetData';
import { Tooltip } from '@mui/material';
import AddSubscription from './AddSubscription';
import EditSubscription from './EditSubscription';

const categoryInitialState = {
  limit: 10,
  pageNo: 0,
  searchQuery: ''
}

const SubscriptionMaster = () => {
  const [editData, setEditData] = useState();
  const [open, setOpen] = useState(null);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { setDataToServer } = useAddData('/deleteSubscription',setOpen,'getSubscriptionList')
  const {handleGetDataList} = useGetData(categoryInitialState,'getSubscriptionList')
  
  const deleteSubscription = (id) => {
    let data = {id}
    setDataToServer.mutate(data);
  }

  const editSubscription = (id, subscription_type, subscription_duration, subscription_price) => {
    setEditData({id, subscription_type, subscription_duration, subscription_price});
    setIsOpenEditModal(true);
  }

  return (
    <>
        <Layout>
          <div className='flex items-center mb-4 justify-between'>
            <h2 className='font-bold text-start mb-4'>Subscription Master</h2>
            <p onClick={() => setIsOpenAddModal(true)} className='h-max w-max rounded-md px-3 py-2 border-dark text-dark hover:cursor-pointer'>Add</p>
          </div>
          {/* drop-shadow-md */}
          <div className='overflow-x-auto relative border  shadow-sm sm:rounded-lg mb-4'>
            <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs  uppercase text-[#518dca] border-b">
                        <tr className='bg-blue-100'>
                            <th scope="col" className="py-3 px-6 text-left">
                                Subscription Name
                            </th>
                            <th scope="col" className="py-3 px-6 text-center">
                                Bill Description
                            </th>
                            <th scope="col" className="py-3 px-6 text-center">
                                Duration
                            </th>
                            <th scope="col" className="py-3 px-6 text-center">
                                Price
                            </th>
                            <th scope="col" className="py-3 px-6 text-center">
                                Total USers
                            </th>
                            <th scope="col" className="py-3 px-6 text-right">
                              
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      { 
                        handleGetDataList?.isLoading ? (
                          <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                            <td className="py-4 px-7 text-center" colSpan={5}>
                              loading...
                            </td>
                          </tr>
                        ) : (
                          handleGetDataList?.data?.data?.length !== 0 ? (
                          handleGetDataList?.data?.data?.map((c) => {
                            const {id, subscription_type, bill_description, subscription_duration, subscription_price,user_count} = c;
                            return (
                              <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50" key={id}>
                                <th scope="row" className="py-3 px-6 text-left font-medium text-gray-900 whitespace-nowrap text-white">
                                    {subscription_type}
                                </th>
                                <td className="py-3 px-6 text-center">
                                    {bill_description}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {subscription_duration}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {subscription_price}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {user_count}
                                </td>
                                <td className="py-3 px-6 text-right flex justify-end">
                                  <svg onClick={() => editSubscription(id, subscription_type, subscription_duration, subscription_price)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer ">
                                    <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                    <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                  </svg>
                                  {
                                    user_count !== 0 ? (
                                      <Tooltip title={`${subscription_type} still has ${user_count} users mapped to it.`} placement="left" arrow>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer text-red-600">
                                          <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                                        </svg>
                                      </Tooltip>
                                    ) : (
                                      <svg onClick={() => deleteSubscription(id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer text-red-600">
                                        <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                                      </svg>
                                    )
                                  }
                                </td>
                              </tr>
                            )}
                          )
                        ): (
                          <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                            <td className="py-4 px-7 text-center" colSpan={5}>
                              No Categories found
                            </td>
                          </tr>
                        )
                        ) 
                      }
                    </tbody>
            </table>
          </div>
        </Layout>
        <Toaster position="top-right" />
        {isOpenAddModal && <AddSubscription open={isOpenAddModal} setOpen={setIsOpenAddModal}/>}  
        {isOpenEditModal && <EditSubscription data={editData} open={isOpenEditModal} setOpen={setIsOpenEditModal}/>}     
    </>
  )
}

export default SubscriptionMaster