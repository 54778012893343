import React from 'react'
import logo from '../../assets/footer.png'
import { AiFillInstagram, AiOutlineTwitter } from 'react-icons/ai'
import { BiLogoLinkedin } from 'react-icons/bi'
import { PiGoogleLogoLight } from 'react-icons/pi'

const Footer = () => {
    return (
        <footer className=' mt-12 bg-[#f2f3f6]'>
            <div className='max-w-7xl mx-auto'>
                <div className=' px-5  lg:px-12 pt-12 pb-5 space-y-4'>
                    <div className="lg:grid grid-cols-3 gap-x-16 space-y-6 lg:space-y-0 lg:mx-[5%]">
                        <div className='lg:translate-y-[-17%]'>
                            <div className=' w-[50%]'>
                                <img src={logo} alt="logo" className='w-full h-full' />
                            </div>
                            <div className=' space-y-'>
                                <h2 className=' font-bold tracking-widest font-montserrat uppercase text-base'>Hitesh Patel </h2>
                                <p className=' font-semibold text-sm font-montserrat'>SEBI Registered Research Analyst</p>
                                <p className='font-montserrat tracking-wide text-sm'>SEBI Registration No INH000009612</p>
                            </div>
                        </div>
                        <div className='tracking-wide'>
                            <h2 className=' font-semibold mb-2 font-montserrat text-sm'>Reach US</h2>
                            <div className=' space-y-2' >
                                <ul>
                                    <strong className=' text-sm text-dark font-semibold font-montserrat'>
                                        <a className='tracking-wide' href="mailto:info@amartarucapital.in">
                                            info@amartarucapital.in
                                        </a>
                                    </strong>
                                    <li className=' text-sm  font-montserrat'>
                                        <a href="tel:+91 7506622757">+91 7506622757</a>
                                    </li>
                                </ul>

                                <p className=' text-sm  font-montserrat font-normal pr-16'>202, Suri Rajendra Tower, R K Singh Marg,Andheri East, Mumbai 400 069</p>
                            </div>
                        </div>
                        <div className='tracking-wide'>
                            <h2 className=' font-semibold mb-2 font-montserrat text-sm'>More Information</h2>
                            <div className='font-montserrat'>
                                <ul className=' text-dark font-semibold leading-[1.4em] text-sm '>
                                    <li>
                                        <a href="https://amartarucapital.in/investor-chart/" target="_blank" rel="noopener noreferrer">
                                            Investor Charter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://amartarucapital.in/investor-grievances/" target="_blank" rel="noopener noreferrer">
                                             Grievances Report
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://amartarucapital.in/grievance-redressal/" target="_blank" rel="noopener noreferrer">
                                             Grievance Redressal
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://amartarucapital.in/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://amartarucapital.in/disclaimer-terms-condition/" target="_blank" rel="noopener noreferrer">
                                            Disclaimer, Terms & Condition
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://smartodr.in/login" target="_blank" rel="noopener noreferrer">
                                         Smart ODR
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                        <div className='col-start-2 col-span-2 tracking-widest  space-y-2 '>
                            <p className=' italic text-sm  font-medium font-montserrat'>
                                <em>
                                    “Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors”
                                </em>
                            </p>
                            <p className=' italic text-sm  font-medium font-montserrat'>
                                <em>“Investments in securities markets are subject to market risks, read all the related documents carefully before investing”</em>
                            </p>
                        </div>
                </div>
            </div>
            <div className=' bg-[#518dca] text-white  py-3'>
                <div className='max-w-7xl mx-auto px-5 lg:px-0'>
                    <div className='lg:flex items-center justify-between font-semibold font-montserrat space-y-4 lg:space-y-0'>
                        <div className=' text-sm'>ⓒ 2022 Amartaru Capital. All rights reserved. Developed by
                            <a href="https://idigitize.co/" target="_blank" rel="noopener nofollow" className='ml-1 hover:underline'>iDigitize </a>
                        </div>
                        <div className='flex items-center justify-center space-x-4'>
                            <a href="https://mobile.twitter.com/AmartaruCapital" target="_blank" rel="noopener noreferrer nofollow">
                                <AiOutlineTwitter className=' w-6 h-6' />
                            </a>
                            <a href="http://www.linkedin.com/in/hiteshpatelra" target="_blank" rel="noopener noreferrer nofollow">
                                <BiLogoLinkedin className=' w-6 h-6' />
                            </a>
                            <a href="https://www.goodreads.com/user/show/52412715-hitesh-patel" target="_blank" rel="noopener noreferrer nofollow">
                                <PiGoogleLogoLight className=' w-6 h-6' />
                            </a>
                            <a href="https://www.instagram.com/amartaru.capital" target="_blank" rel="noopener noreferrer nofollow">
                                <AiFillInstagram 
                                className=' w-6 h-6' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer