import { useState } from "react"
import { PasswordInput } from "./PasswordInput"

function FormElement(props) {
    const { label, element, onChange, errorMessage, optionValue, toggleText, ...inputProps } = props
    const [focused, setFocused] = useState(false)
    const handleFocused = () => {
        setFocused(false)
    }
    const elements = () => {
        switch (element) {
            case 'input':
                return inputProps['name'] === 'password' || inputProps['name'] === 'confirm_password' ? (
                    <PasswordInput {...inputProps} focused={focused.toString()} onChange={onChange} onBlur={handleFocused} />
                ) : (
                    <input {...inputProps} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline' focused={focused.toString()} onChange={onChange} onBlur={handleFocused} />
                )
            case 'select':
                return <select {...inputProps} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline' focused={focused.toString()} onChange={onChange} onBlur={handleFocused}>
                    <option className="" value="">--Select--</option>
                    {optionValue?.map((item, idx) => {
                        return <option className="py-4" key={idx} value={item.value || item.id}>{item.name}</option>
                    })}
                </select>
            case 'textarea':
                return <textarea {...inputProps} className='shadow appearance-none border rounded w-full min-h-[2.4rem] h-[2.45rem] py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline' focused={focused.toString()} onChange={onChange} onBlur={handleFocused}></textarea>
            default:
                break;
        }
    }
    return (
        <div>
            <label htmlFor={label} className='block text-gray-700 text-sm font-bold mb-2'>{label} {props.required && <span className='mandatory text-red-700'>*</span>}</label>
            {elements()}
            <small className=" text-red-700 font-semibold errorMessage mt-1">{errorMessage}</small>
        </div>
    )
}

export default FormElement