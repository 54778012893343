import React, { useState } from 'react'
import UserLayout from '../../../components/Layout/UserLayout'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../hooks/useGetData'
import Moment from 'react-moment'
import Loader from '../../../components/Loader'
import Success from '../../Status/Success'
import { IoMdDownload } from "react-icons/io";
import useDownloadFile from '../../../hooks/useDownloadFile'
import { FiLoader } from "react-icons/fi";
// const getBgColor = (rating) => {
//     if (rating === "Exit") {
//       return "bg-red-100 text-red-500 border border-red-500 shadow"
//     } else if (rating === "Buy") {
//       return "bg-green-50 text-green-500 border border-green-500 shadow"
//     } 
//   }
let data = [
    {
        "id": "40",
        "subscription_id": "1",
        "payment_method": "ONLINE",
        "total_amount": "18000.00",
        "order_date": "1705914784",
        "subscription_type": "Paid",
        "subscription_start_date": "1677781800",
        "subscription_end_date": "1705429800"
    },
    {
        "id": "39",
        "subscription_id": "1",
        "payment_method": "ONLINE",
        "total_amount": "18000.00",
        "order_date": "1705911907",
        "subscription_type": "Paid",
        "subscription_start_date": "1677781800",
        "subscription_end_date": "1705429800"
    },
    {
        "id": "38",
        "subscription_id": "1",
        "payment_method": "ONLINE",
        "total_amount": "18000.00",
        "order_date": "1705911884",
        "subscription_type": "Paid",
        "subscription_start_date": "1677781800",
        "subscription_end_date": "1705429800"
    },
    {
        "id": "37",
        "subscription_id": "1",
        "payment_method": "ONLINE",
        "total_amount": "18000.00",
        "order_date": "1705911378",
        "subscription_type": "Paid",
        "subscription_start_date": "1677781800",
        "subscription_end_date": "1705429800"
    },
    {
        "id": "36",
        "subscription_id": "1",
        "payment_method": "ONLINE",
        "total_amount": "18000.00",
        "order_date": "1705911114",
        "subscription_type": "Paid",
        "subscription_start_date": "1677781800",
        "subscription_end_date": "1705429800"
    }
]

const Orders = () => {
    const [selectedId,setSelectedId] = useState(null)
    const { reportDownload } = useDownloadFile({
         url: 'generate_invoice', 
         filename: 'invoice.pdf',
         onSuccess: () => {
            setSelectedId(null)
         },
         onError: (err) => {
            // let errorMessage = err?.response
            setSelectedId(null)
            if(err.response.status === 400){
                alert('No subscription details found')
            }
         }
    });
  const handleDownloadInvoice = ( order_id) => {
    setSelectedId(order_id);
    // Call reportDownload function when the invoice button is clicked
    reportDownload.mutate({
         order_id
    });
  };
    const initialData = {
        per_page: 50,
        page_no: 0
    }
    const { handleGetDataList: userOrders } = useGetData(initialData, 'order_history')
    const navigate = useNavigate()
    return (
        <UserLayout>
            <div className='w-full md:max-w-[35rem] mx-auto min-screen-height'>
                {userOrders.isLoading
                    ? <div className='flex-1'>
                        <Loader />
                    </div>
                    : <>
                        <div className='flex w-max mb-4 md:mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate('/stocks')}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                            </svg>
                            <p className='ml-1 md:mb-[3px] text-sm hover:text-underline'>Back</p>
                        </div>
                        <div className='flex flex-row mb-6'>
                            <h1 className='font-bold'>Orders</h1>
                        </div>
                        <div className=' space-y-4'>
                            {userOrders?.data?.data.length
                            ? userOrders?.data?.data?.map((item, idx) => (<OrdersCard key={idx} selectedId={selectedId} data={item} onClickDownloadInvoice={handleDownloadInvoice}/>))
                            : <NoOrdersFound />}
                        </div>
                    </>}
            </div>
        </UserLayout>
    )
}


const OrdersCard = ({ data,onClickDownloadInvoice,selectedId }) => {
    
    const { order_id, order_date, subscription_end_date, subscription_start_date, total_amount, transaction_id,order_status } = data || {}
    return<div className=''>
        <div className=' bg-gray-100 rounded shadow p-3'>
            <div className='flex  '>

        <p className=' basis-[80%] text-sm text-gray-600'>Order id : {order_id}</p>
        <div className={`py-1 basis-[2  0%] rounded px-4 font-semibold text-center text-[11px] ${(order_status) === 'Success'?'text-green-500 bg-green-100 border border-green-500' : order_status === 'Pending' ? 'text-blue-500  bg-blue-100 border border-blue-500':  'text-red-500 bg-red-100 border border-red-500'}`}>
    {order_status}
    </div>
            </div>
        
        <p className=' text-sm text-gray-600'>Order date : <Moment unix date={order_date} format='ll' /></p>
        <p className=' text-sm text-gray-600'>Subscription start date :{subscription_start_date ? <Moment unix date={subscription_start_date} format='LL' />: ' --'}</p>
        <p className=' text-sm text-gray-600'>Subscription end date :{subscription_end_date ?  <Moment unix date={subscription_end_date} format='ll' />: ' --'}</p>
        <p className=' text-sm text-gray-600'>Transaction id : <span className=' text-xs'>{transaction_id}</span> </p>
        <div className='flex justify-between items-center pr-6'>

        <div className='flex basis-[80%]  '>
            
        <p className=' font-bold    text-lg'>Total Amount : {total_amount}</p>
        </div>
        {order_status === 'Success' && (<button  disabled={selectedId === order_id ? true : false}  onClick={()=>onClickDownloadInvoice(order_id)} className='flex pr-3   justify-between text-gray-600 hover:text-blue-600 text-sm  space-x-2 items-center'>
        
        <IoMdDownload disabled={order_status === 'Pending'} className='w-4 h-4'/>
        
        <span className=' font-semibold'>Invoice</span>
        {selectedId === order_id && <FiLoader className='w-4 h-4 animate-spin text-gray-600'/>}
        </button>)}
        </div>
        
        
        
    
    </div>
    
    </div> 
}

const NoOrdersFound = () => {
    return <div className=' text-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="w-28 h-28 text-[#ef756e] mx-auto mb-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
        </svg>

        <p className='font-semibold text-xl text-[#ef756e]'>Welcome to your Orders page! Currently, there are no subscribed orders. Dive into our offerings and subscribe to start enjoying exclusive benefits.</p>
    </div>
}

export default Orders