import React, { useEffect } from "react";

const TwitterContainer = ({ css }) => {
  useEffect(() => {
    // const anchor = document.createElement("a");
    // anchor.setAttribute("class", "twitter-timeline");
    // anchor.setAttribute("data-theme", "light");
    // anchor.setAttribute("data-tweet-limit", "5");
    // anchor.setAttribute("data-chrome", "noheader nofooter noborders");
    // anchor.setAttribute("href", "https://twitter.com/capitalideaz?ref_src=twsrc%5Etfw");
    // document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

    // const script = document.createElement("script");
    // script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    // document.getElementsByClassName("twitter-timeline")[0].appendChild(script);
  }, []);

  return (
    <section className={css + " twitterContainer overflow-auto border shadow-lg rounded-lg "}>
      <p className="py-3 px-4 bg-[#1d9bf0] text-white font-bold">Twitter Timeline</p>
      <div className="twitter-embed"></div>
      <a 
      class="twitter-timeline" 
      data-chrome="nofooter noborders"
      href="https://twitter.com/AmartaruCapital?ref_src=twsrc%5Etfw">
      </a> 
      {/* <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Product tanker shipping is facing a disruption due to ongoing events in Red Sea and the freight rates continue to move higher <a href="https://twitter.com/hashtag/ProductTanker?src=hash&amp;ref_src=twsrc%5Etfw">#ProductTanker</a> <a href="https://twitter.com/hashtag/OilTanker?src=hash&amp;ref_src=twsrc%5Etfw">#OilTanker</a> <a href="https://twitter.com/hashtag/Shipping?src=hash&amp;ref_src=twsrc%5Etfw">#Shipping</a> <a href="https://t.co/HkyGNftFly">https://t.co/HkyGNftFly</a> <a href="https://t.co/SvDs0Vhedm">pic.twitter.com/SvDs0Vhedm</a></p>&mdash; Capital Ideas (@capitalideaz) <a href="https://twitter.com/capitalideaz/status/1751134722535919995?ref_src=twsrc%5Etfw">January 27, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
    </section>
  );
};

export default TwitterContainer;