import { useRef, useState } from 'react'
import FormElement from '../../../components/Form/FormElement'
import Layout from '../../../components/Layout'
import Loader from '../../../components/Loader'
import useAddData from '../../../hooks/useAddData'
import EditorRC from '../../../components/Editor/EditorRc'
import { useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import useGetData from '../../../hooks/useGetData'
const updates = {
    title: '',
    description: '',
    report_type: '',
    blog: '',
    share: '',
    download: '',
    document_file: null,
    document_file_name: '',
    upload_type_id: ''
}
const generalInitialState = {
    screen:'general'
  }
const EditGeneral = () => {
    const navigate = useNavigate()
    const editorRef = useRef(null)
    let params = useParams();
    // console.log(params);
    const [updatesData, setUpdatesData] = useState(updates)
    const [updatesTypeOptions, setUpdateTypeOptions] = useState([])
    const handleIndividualUpdateSuccess = (data) => {
        const temp = data.data[0]
        setUpdatesData({ ...temp })
      }
    const { handleGetDataList: getUpdateData } = useGetData({ id: params.id }, 'getIndividualGeneralUpdates', handleIndividualUpdateSuccess)
    const { setDataToServer } = useAddData('/editGeneralDocument', () => {
        navigate(-1)
        toast.success('General Content Added Successfully')
    }, 'getGeneralUploadList')
    const handleFormSubmit = (e) => {
        e.preventDefault()
        e.preventDefault()
        let data = {}
        if (updatesData['report_type'] === 'BLOG') {
            data = {
                ...updatesData,
                document_file: null,
                document_file_name: null,
                blog: editorRef.current.getContent()
            }
        } else {
            data = {
                ...updatesData,
                blog: null
            }
        }
        setDataToServer.mutate(data)
    }
    const handleSetFileData = (e) => {
        let value = e.target.files[0];
        let data = {
            ...updatesData,
            document_file: value,
            document_file_name: e.target.files[0].name
        }
        setUpdatesData(data);
    }
    const handleSetFormData = (e) => {
        let value = e.target.value;
        let data = {
            ...updatesData,
            [e.target.name]: value
        }
        setUpdatesData(data);
    }
    const handleUpdateTypeSuccess = (data) => {
        let temp = data.data
        let tempArr = []
        temp.forEach(it => {
          let data = {
            value: it.upload_type_id,
            name: it.type
          }
          tempArr.push(data)
        });
        setUpdateTypeOptions(tempArr)
      }
      const { handleGetDataList } = useGetData(generalInitialState, 'getUpdateType', handleUpdateTypeSuccess)
    return (
        <Layout>
            <div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                </svg>
                <p className='ml-1'>Edit General or StockTalk updates</p>
            </div>
            <form onSubmit={handleFormSubmit}>
                <div className='grid grid-cols-2 auto-rows-auto gap-6 gap-x-6 mt-6'>
                    <FormElement
                        name="title"
                        onChange={handleSetFormData}
                        placeholder="Enter Title"
                        label="Title"
                        type="text"
                        required={true}
                        value={updatesData['title']}
                        element='input'
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    <FormElement
                        name="description"
                        onChange={handleSetFormData}
                        placeholder="Enter Description"
                        label="Description"
                        value={updatesData['description']}
                        type="text"
                        required={true}
                        element='input'
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    <FormElement
                        name="download"
                        onChange={handleSetFormData}
                        placeholder="Enter Description"
                        label="Downloadable"
                        type="text"
                        required={true}
                        element="select"
                        optionValue={[
                            { name: 'Yes', value: 'true' },
                            { name: 'No', value: 'false' },
                        ]}
                        value={updatesData['download']}
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    <FormElement
                        name="share"
                        onChange={handleSetFormData}
                        placeholder="Enter Description"
                        label="Shareable"
                        type="text"
                        required={true}
                        element="select"
                        optionValue={[
                            { name: 'Yes', value: 'true' },
                            { name: 'No', value: 'false' },
                        ]}
                        value={updatesData['share']}
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    <FormElement
                        name="report_type"
                        onChange={handleSetFormData}
                        placeholder="Enter Description"
                        label="Report Type"
                        type="text"
                        required={true}
                        element="select"
                        optionValue={[
                            { name: 'Blog', value: 'BLOG' },
                            { name: 'PDF', value: 'PDF' },
                        ]}
                        value={updatesData['report_type']}
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    <FormElement
                        name="upload_type_id"
                        onChange={handleSetFormData}
                        placeholder="Enter Description"
                        label="Update Type"
                        type="text"
                        required={true}
                        element="select"
                        optionValue={updatesTypeOptions}
                        value={updatesData['upload_type_id']}
                        errorMessage='This Field Cannot Be Empty !'
                    />
                    {
                        (updatesData['report_type'] === "PDF") ?
                            <div>
                                <label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Report Document </label>
                                <input
                                    name="report_file"
                                    placeholder='Enter Category'
                                    type='file'
                                    onChange={handleSetFileData}
                                    value={updatesData['report_file']}
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                />
                            </div> : null
                    }
                </div>
                {
                    updatesData['report_type'] === "BLOG" && (
                        <div className="mt-6">
                            <label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Blog </label>
                            <EditorRC value={updatesData['blog']} editorRef={editorRef} />
                        </div>
                    )
                }
            <button type='submit' className='h-max mt-6 w-max rounded-md px-3 py-2 text-dark border-dark hover:cursor-pointer flex items-center'>Update {setDataToServer?.isLoading && <Loader isInline={true} />}</button>
            </form>
        </Layout>
    )
}

export default EditGeneral