import React from 'react'
import Sidebar from '../Sidebar'
import Footer from '../footer'

function Layout({ children }) {
    return (
        <div className=''>
            <Sidebar />
            <div className='h-max grow ml-[250px] p-12'>
                <div className=' main_content'>
                    {children}
                </div>
                {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Layout
