import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FormElement from '../../../components/Form/FormElement';
import Layout from '../../../components/Layout'
import { COMPANY_DETAILS, REPORT_FILE, REPORT_TYPE, SMART_BUY, SMART_HOLD, SMART_RATING } from '../CompanyProfile/companyInputs';
import EditorRc from '../../../components/Editor/EditorRc';
import ToggleElement from '../../../components/Form/ToggleElement';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import useAddData from '../../../hooks/useAddData';
import useGetData from '../../../hooks/useGetData';
import { Toaster } from 'react-hot-toast';

const data = {
	company_name: "",
	company_description: "",
	bse_code: "",
	nse_code: "",
	average_buy_price: "",
	industry: "",
	sizing_guide: "",
	rating: "",
	company_link: '',
	short_code: '',
	subscription_id: [],
	category: "",
	hide: false,
	report_type: "",
	report_file: null,
	blog: '',
	share: '',
	download: ''
};

const NOT_REQUIRED_OPTIONS = ['average_buy_price', 'sizing_guide', 'bse_code', 'nse_code']

const AddCompany = () => {
	const navigate = useNavigate();
	const [subscription, setSubscription] = useState([]);
	const [categories, setCategories] = useState([]);
	const { setDataToServer } = useAddData('/createCompany', () => navigate('/company-master'), 'getCompanyList')
	const { handleGetDataList: subscriptionList } = useGetData({}, 'getSubscriptionList')
	const { handleGetDataList: categoryList } = useGetData({}, 'getCategoryList')
	const [companyData, setCompanyData] = useState(data);
	const editorRef = useRef(null);

	const handleSetFormData = (e) => {
		let value = e.target.value
		let data = {
			...companyData,
			[e.target.name]: value
		}
		setCompanyData(data)
	}

	const handleSelect = (key, item) => {
		let value = item
		let data = {
			...companyData,
			[key]: JSON.stringify(value)
		}
		setCompanyData(data)
	}

	const handleSelectCategory = (key, item) => {
		let value = item
		let data = {
			...companyData,
			[key]: value
		}
		setCompanyData(data)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (companyData.report_type === "BLOG") {
			let tempData = {
				...companyData,
				category: (companyData['category'].length > 0 && companyData['category']) ? companyData['category'][0]?.label : '',
				blog: editorRef.current.getContent()
			}
			// console.log('companyData',companyData['category'].length)
			setDataToServer.mutate(tempData);
		} else {
			let tempData = {
				...companyData,
				category: (companyData['category'].length > 0 && companyData['category']) ? companyData['category'][0]?.label : '',
			}
			// console.log('companyData',companyData['category'].length)
			setDataToServer.mutate(tempData);
		}
	}

	const handleSetFileData = (e) => {
		let value = e.target.files[0];
		let data = {
			...companyData,
			[e.target.name]: value,
		}
		setCompanyData(data);
	}

	useEffect(() => {
		if (subscription.length === 0) {
			const tempArr = []
			subscriptionList?.data?.data?.map((item) => {
				let data = {
					value: item.id,
					label: item.subscription_type
				}
				return tempArr.push(data)
			})
			setSubscription(tempArr);
		}
	}, [subscriptionList, subscription.length])

	useEffect(() => {
		if (categories.length === 0) {
			const tempArr = []
			categoryList?.data?.data?.map((item) => {
				let data = {
					value: item.id,
					label: item.name
				}
				return tempArr.push(data)
			})
			setCategories(tempArr);
		}
	}, [categoryList, categories.length])


	return (
		<>

			<Layout>
				<div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
						<path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
					</svg>
					<p className='ml-1'>Company Master</p>
				</div>
				<h1 className='text-xl font-bold'>Add Company</h1>
				<form onSubmit={handleFormSubmit}>
					<div className='grid lg:grid-cols-2 auto-rows-auto my-8 gap-3 gap-x-6'>
						{
							COMPANY_DETAILS.map((input, idx) => {
								const { name } = input;
								return <FormElement
									key={idx}
									{...input}
									value={companyData[name]}
									required={companyData['recommend'] === '2' ? (NOT_REQUIRED_OPTIONS.includes(name) ? false : true) : true}
									onChange={handleSetFormData}
								/>
							})
						}
						<div className='relative'>
							<label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Industry </label>
							<CreatableSelect
								className='shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none shadow-outline'
								isClearable
								required
								options={categories}
								styles={{
									control: base => ({
										...base,
										border: 0,
										// This line disable the blue border
										boxShadow: 'none'
									})
								}}
								onChange={(item) => handleSelectCategory("category", [item])}
							/>
						</div>
						<div className='relative'>
							<label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Show to these plans</label>
							<Select
								className='shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none shadow-outline'
								isClearable
								isMulti
								options={subscription}
								styles={{
									control: base => ({
										...base,
										border: 0,
										// This line disable the blue border
										boxShadow: 'none'
									})
								}}
								onChange={(item) => handleSelect("subscription_id", item)}
							/>
						</div>

						{/* <ToggleElement 
								name={'hide'}
								label={'Hide'}
								required={true}
								errorMessage={'This Field Cannot Be Empty !'}
								toggleText={'Show only to premium users'}
								data={companyData}
								setData={setCompanyData}
							/> */}


						{/* <div className='relative'>
								{props.required && <span className='mandatory'>*</span>}
								<label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Category </label>
								<input 
									name="category" 
									placeholder='Enter Category' 
									focused={focused.toString()} 
									onChange={handleSetFormData} 
									onBlur={handleFocused} 
									value={companyData['category']} 
									onClick={() => setIsSearchDropDownVisible(!isSearchDropDownVisible)}
									className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
								/>
								{isSearchDropDownVisible &&
									<div className='bg-white absolute shadow-lg appearance-none border rounded w-[100%] mt-2 p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline z-50 '>
										<p onClick={() => {setCompanyData({ ...companyData, category : "Fintech"}); setIsSearchDropDownVisible(false)}} className='py-2 px-3 rounded hover:cursor-pointer hover:bg-gray-200'>Fintech</p>
										<p onClick={() => {setCompanyData({ ...companyData, category : "Food Tech"}); setIsSearchDropDownVisible(false)}}  className='py-2 px-3 rounded hover:cursor-pointer hover:bg-gray-200'>Food Tech</p>
										<p onClick={() => {setCompanyData({ ...companyData, category : "Fintech"}); setIsSearchDropDownVisible(false)}} className='py-2 px-3 rounded hover:cursor-pointer hover:bg-gray-200'>Fintech</p>
										<p onClick={() => {setCompanyData({ ...companyData, category : "Food Tech"}); setIsSearchDropDownVisible(false)}}  className='py-2 px-3 rounded hover:cursor-pointer hover:bg-gray-200'>Food Tech</p>
									</div>
								}
								{!companyData['category'] && <small className=" text-red-700 font-semibold errorMessage mt-1">This Field Cannot Be Empty !</small>}
							</div> */}

					</div>
					{/* Smart Rating features */}
					{/* <div className='mt-8'>			
								<h2 className='font-bold mb-2'>Smart Rating</h2>
								<div className='grid lg:grid-cols-2 auto-rows-auto my-8 gap-3 gap-x-6'>
									{
											SMART_RATING.map((input,idx) => {
													const {name} = input;
													return <FormElement 
															key={idx}
															{...input}
															value={companyData[name]}
															onChange={handleSetFormData}
													/>
											})
									}
									{companyData['rating'] !== 'Sell' && <ToggleElement 
										name={'smart_rating'}
										label={'Smart Rating'}
										required={true}
										errorMessage={'This Field Cannot Be Empty !'}
										toggleText={'Enable smart rating'}
										data={companyData}
										setData={setCompanyData}
									/>}
									{
										companyData['smart_rating'] && (
												companyData['rating'] === "Buy" ? (
													SMART_HOLD.map((input,idx) => {
														const {name} = input;
														return <FormElement 
															key={idx}
															{...input}
															value={companyData[name]}
															onChange={handleSetFormData}
														/>
													})
												) : null
										)
									}
									{
										companyData['smart_rating'] && (
												companyData['rating'] === "Hold" ? (
													SMART_BUY.map((input,idx) => {
														const {name} = input;
														return <FormElement 
															key={idx}
															{...input}
															value={companyData[name]}
															onChange={handleSetFormData}
														/>
													})
												) : null
										)
									}
								</div>
							</div> */}
					<div className='mt-8 col-span-2'>
						<h2 htmlFor="category_search" className='block font-bold mb-8'>Initial Report </h2>
						<div className='grid lg:grid-cols-2 auto-rows-auto my-8 gap-3 gap-x-6'>
							{
								REPORT_TYPE.map((input, idx) => {
									const { name } = input;
									return <FormElement
										key={idx}
										{...input}
										value={companyData[name]}
										onChange={handleSetFormData}
									/>
								})
							}
							{
								(companyData['report_type'] === "PPT" || companyData['report_type'] === "PDF") ?
									<div>
										<label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Category </label>
										<input
											name="report_file"
											placeholder='Enter Category'
											type='file'
											onChange={handleSetFileData}
											className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
										/>
									</div> : null
							}
						</div>
						{
							companyData['report_type'] === "BLOG" && <EditorRc editorRef={editorRef} />
						}
						<button type='submit' className='h-max mt-6 w-max rounded-md px-3 py-2 text-dark  border-dark hover:cursor-pointer'>Add Company</button>
					</div>
				</form>
			</Layout>
			<Toaster position="top-right" />
		</>
	)
}

export default AddCompany