import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import FormElement from '../../../components/Form/FormElement';
import Layout from '../../../components/Layout'
import { COMPANY_DETAILS, REPORT_FILE, REPORT_TYPE, SMART_BUY, SMART_HOLD, SMART_RATING } from '../CompanyProfile/companyInputs';
import EditorRc from '../../../components/Editor/EditorRc';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import useAddData from '../../../hooks/useAddData';
import useGetData from '../../../hooks/useGetData';
import { Toaster } from 'react-hot-toast';
import Loader from '../../../components/Loader';

const data = {
	company_name: "",
	company_description: "",
	bse_code: "",
	nse_code: "",
	average_buy_price: "",
	industry: "",
	sizing_guide: "",
	rating: "",
	subscription_id: "",
	category: "",
	hide: false,
	report_type: "",
	report_file: null,
	blog: '',
	share: '',
	download: ''
};

const NOT_REQUIRED_OPTIONS = ['average_buy_price', 'sizing_guide', 'bse_code', 'nse_code']

const EditCompany = () => {
	const navigate = useNavigate();
	let params = useParams();
	const [initialRender, setInitialRender] = useState(false);
	const [subscription, setSubscription] = useState([]);
	const [categories, setCategories] = useState([]);

	const { handleGetDataList: subscriptionList } = useGetData({}, 'getSubscriptionList')
	const { handleGetDataList: categoryList } = useGetData({}, 'getCategoryList')
	const [companyData, setCompanyData] = useState(data);
	const editorRef = useRef(null);

	const { handleGetDataList: companyDetails } = useGetData({ id: params.companyId }, 'getCompanyDetails')
	const { setDataToServer } = useAddData('/updateCompany', () => navigate('/company-master/' + params.companyId), 'getCompanyList')

	useEffect(() => {
		if (companyDetails.isFetched) {
			if (!initialRender) {
				setCompanyData(companyDetails?.data?.data[0]);
				setInitialRender(true);
			}
		}
	}, [companyDetails, initialRender])

	const handleSetFormData = (e) => {
		let value = e.target.value
		let data = {
			...companyData,
			[e.target.name]: value
		}
		// console.log(value)
		setCompanyData(data)
	}

	const handleSelect = (key, item) => {
		let value = item
		let data = {
			...companyData,
			[key]: value
		}
		setCompanyData(data)
	}

	const handleSelectCategory = (key, item) => {
		let value = item
		let data = {
			...companyData,
			[key]: value
		}
		setCompanyData(data)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (companyData.report_type === "BLOG") {
			let tempData = {
				...companyData,
				blog: editorRef.current.getContent(),
				category: companyData['category'][0].label,
				subscription_id: JSON.stringify(companyData['subscription_id'])
			}
			setDataToServer.mutate(tempData);
		} else {
			let tempData = {
				...companyData,
				category: companyData['category'][0].label,
				subscription_id: JSON.stringify(companyData['subscription_id'])
			}
			setDataToServer.mutate(tempData);
		}
	}

	const handleSetFileData = (e) => {
		let value = e.target.files[0];
		let data = {
			...companyData,
			[e.target.name]: value,
		}
		setCompanyData(data);
	}

	useEffect(() => {
		if (subscription.length === 0) {
			const tempArr = []
			subscriptionList?.data?.data?.map((item) => {
				let data = {
					value: item.id,
					label: item.subscription_type
				}
				return tempArr.push(data)
			})
			setSubscription(tempArr);
		}
	}, [subscriptionList, subscription.length])

	useEffect(() => {
		if (categories.length === 0) {
			const tempArr = []
			categoryList?.data?.data?.map((item) => {
				let data = {
					value: item.id,
					label: item.name
				}
				return tempArr.push(data)
			})
			setCategories(tempArr);
		}
	}, [categoryList, categories.length])


	return (
		<>

			<Layout>
				<div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
						<path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
					</svg>
					<p className='ml-1'>Company Master</p>
				</div>
				<h1 className='text-xl font-bold'>Edit Company</h1>
				{
					!companyDetails.isFetched ? (
						<p>Loading...</p>
					) : (
						<form onSubmit={handleFormSubmit}>
							<div className='grid lg:grid-cols-2 auto-rows-auto my-8 gap-3 gap-x-6'>
								{
									COMPANY_DETAILS.map((input, idx) => {
										const { name } = input;
										return <FormElement
											key={idx}
											{...input}
											required={companyData['recommend'] === '2' ? (NOT_REQUIRED_OPTIONS.includes(name) ? false : true) : true}
											value={companyData[name]}
											onChange={handleSetFormData}
										/>
									})
								}
								<div className='relative'>
									<label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Industry </label>
									<CreatableSelect
										className='shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none shadow-outline'
										isClearable
										options={categories}
										value={companyData['category']}
										styles={{
											control: base => ({
												...base,
												border: 0,
												// This line disable the blue border
												boxShadow: 'none'
											})
										}}
										onChange={(item) => handleSelectCategory("category", [item])}
									/>
								</div>
								<div className='relative'>
									<label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Show to these plans</label>
									<Select
										className='shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none shadow-outline'
										isClearable
										isMulti
										options={subscription}
										value={companyData['subscription_id']}
										styles={{
											control: base => ({
												...base,
												border: 0,
												// This line disable the blue border
												boxShadow: 'none'
											})
										}}
										onChange={(item) => handleSelect("subscription_id", item)}
									/>
								</div>
							</div>
							<div className='mt-8 col-span-2'>
								<h2 htmlFor="category_search" className='block font-bold mb-8'>Initial Report </h2>
								<div className='grid lg:grid-cols-2 auto-rows-auto my-8 gap-3 gap-x-6'>
									{
										REPORT_TYPE.map((input, idx) => {
											const { name } = input;
											return <FormElement
												key={idx}
												{...input}
												value={companyData[name]}
												onChange={handleSetFormData}
											/>
										})
									}
									{
										(companyData['report_type'] === "PPT" || companyData['report_type'] === "PDF") ?
											<div>
												<label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Report Document </label>
												<input
													name="report_file"
													placeholder='Enter Category'
													type='file'
													onChange={handleSetFileData}
													// value={companyData['report_file']}
													className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
												/>
											</div> : null
									}
								</div>
								{
									companyData['report_type'] === "BLOG" && <EditorRc value={companyData['blog']} editorRef={editorRef} />
								}
								<button type='submit' className='h-max mt-6 w-max rounded-md px-3 py-2 text-dark border-dark hover:cursor-pointer flex items-center'>Save {setDataToServer.isLoading && <Loader isInline={true} />}</button>
							</div>
						</form>
					)
				}
			</Layout>
			<Toaster position="top-right" />
		</>
	)
}

export default EditCompany