import React from 'react'
import UserLayout from '../../components/Layout/UserLayout'
import Payment from '../../components/payment'

const Membership = () => {
  return (
    <UserLayout>
      <div className='text-justify max-w-3xl mx-auto'>
        <h1 className='font-bold text-start text-xl mb-4 mt-4'>Membership</h1>
        <p className='mb-2'>The membership covers the stock ideas along with regular updates in a simple and easy to understand format. We provide this service under SEBI Research Analyst regulation (SEBI Registration No INH000009612). Please read our <a className='underline underline-offset-1' rel="noreferrer" href='https://amartarucapital.in/disclaimer-terms-condition/' target='_blank'>Disclaimer, Terms of use</a>, <a className='underline underline-offset-1' rel="noreferrer" href='https://amartarucapital.in/faqs/' target='_blank'>FAQ</a>, and <a className='underline underline-offset-1' rel="noreferrer" href='https://amartarucapital.in/privacy-policy/' target='_blank'>Privacy Policy</a> before subscribing to our Service.</p>
        <div className='border mx-auto w-full md:w-max md:min-w-72 my-8 rounded'>
          <div className='bg-light flex justify-center md:px-36'>
            <p className="text-xl font-bold py-2 md:py-4 ">Annual Subscription</p>
          </div>
          <div>
            <div className='flex flex-col flex-wrap items-center md:px-36 h-36 justify-center border-b-2'>
              <p className='text-xl font-semibold'>Rs 18,000</p>
              <p className='text-sm'>( Valid for 12 months )</p>
              
              <Payment/>
            </div>
          </div>
          <div className='flex flex-col items-center py-4'>
            <p className='font-semibold text-lg my-1 capitalize'>stock ideas</p>
            <p className='font-semibold text-lg my-1 capitalize'>research reports</p>
            <p className='font-semibold text-lg my-1 capitalize'>Sizing guides</p>
            <p className='font-semibold text-lg my-1 capitalize'> regular updates</p>
          </div>
        </div>
        <p className='mb-2'>You can also use below mentioned banking details or UPI Id for the direct bank transfer and send the transaction details on the info@amartarucapital.in. We do not accept cash payment so do not deposit cash.  Please note It may take 24 hours to activate your account.</p>
        <table className='border my-8 mx-auto rounded'>
          <tr className='border-b-2'>
            <td className='py-3 px-6 text-left border-r-2 font-medium bg-light' colSpan={2}>Account Details</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>Name</td>
            <td className='py-3 px-6 text-left'>Capital Ideas Analytics</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>Bank</td>
            <td className='py-3 px-6 text-left'>Kotak Mahindra Bank</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>A/c No</td>
            <td className='py-3 px-6 text-left'>4047257403</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>Account Type</td>
            <td className='py-3 px-6 text-left'>Current</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>Branch</td>
            <td className='py-3 px-6 text-left'>Hubtown Solaris, Mumbai</td>
          </tr>
          <tr>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>IFSC</td>
            <td className='py-3 px-6 text-left'>KKBK0001365</td>
          </tr>
          <tr className='border-t-2'>
            <td className='py-3 px-6 text-left border-r-2 font-medium'>UPI Details</td>
            <td className='py-3 px-6 text-left'>capitalideas@kotak</td>
          </tr>
        </table>
        {/* <p className='font-semibold text-md my-4 '>Use below mentioned banking details or UPI Id for the direct bank transfer and send the transaction details on the info@capitalideaz.in. We do not accept cash payment so do not deposit cash.  Please note It may take 24 hours to activate your account.</p>
        <p className='mb-4'>Use below mentioned banking details or UPI Id for the direct bank transfer and send the transaction details on the info@capitalideaz.in</p>
        <p className='mb-2'><span className='font-semibold'>Bank: </span>Kotak Mahindra Bank</p>
        <p className='mb-2'><span className='font-semibold'>Name: </span>Capital Ideas Analytics</p>
        <p className='mb-2'><span className='font-semibold'>A/c No: </span>4047257403</p>
        <p className='mb-2'><span className='font-semibold'>Account Type: </span>Current</p>
        <p className='mb-2'><span className='font-semibold'>Branch: </span>Hubtown Solaris, Mumbai</p>
        <p className='mb-2'><span className='font-semibold'>IFSC: </span>KKBK0001365</p>
        <p className='mb-6'><span className='font-semibold'>UPI ID: </span>capitalideas@kotak</p>
        <p className='mb-2'><span className='font-semibold'>Cost: </span>18000/-</p> */}
      </div>
    </UserLayout>
  )
}

export default Membership