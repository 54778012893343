import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import axios, { FRONT_END_URL } from '../../api/axios';
import FormElement from '../../components/Form/FormElement';
import { errorToast, successToast } from '../../hooks/useAddData';
import { FORGET_PASSWORD_DETAILS } from './authInputs';
import HeroPage from './HeroPage'

const ForgotPassword = () => {
  const location = useLocation()
  const [userData, setUserData] = useState({ email: '' });
  const [error, setError] = useState(false);

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...userData,
      [e.target.name]: value
    }
    setUserData(data)

  }

  const handleAuth = useMutation((data) => handleFormSubmit(data), {
    onSuccess: (data) => {
      successToast('Email Sent')
    },
    onError: (err) => {
      errorToast(err.response.data.message)
      setError(true);
    }
  })

  const handleFormSubmit = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    const response = await axios({
      method: 'POST',
      url: '/forgot_password',
      data: fd,
      // withCredentials: true, 
    })
    return response
  }

  const handleAuthSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...userData,
      url: FRONT_END_URL + 'reset-password?code=',
    }
    handleAuth.mutate(data)
  }

  return (
    <>
      <div className='h-max lg:h-screen w-screen flex flex-col md:flex-row'>
        <div className='flex flex-col w-full h-max lg:flex-row lg:w-[75vw] lg:h-[70vh] lg:m-auto lg:border lg:border-solid lg:rounded-lg lg:shadow-lg'>
          <HeroPage />
          <div className='w-full lg:w-1/2 h-max px-4 md:px-12 m-auto py-12 lg:pt-0 md:py-0 md:my-auto'>
            <form onSubmit={handleAuthSubmit} autoComplete='off'>
              <div className='grid grid-cols-1 auto-rows-auto gap-3 gap-x-6 max-w-sm m-auto mt-4'>
                {/* <h1 className='font-bold mb-2'>Forgot Password</h1> */}
                {
                  FORGET_PASSWORD_DETAILS.map((input, idx) => {
                    const { name } = input;
                    return <FormElement
                      key={idx}
                      {...input}
                      value={userData[name]}
                      onChange={handleSetFormData}
                    />
                  })
                }
                {
                  handleAuth.isLoading ? (
                    <button type='submit' className='flex items-center justify-center bg-light text-white py-2 rounded-md mt-4'>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      .. Loading
                    </button>
                  ) : (
                    <button type='submit' className='bg-light text-white py-2 rounded-md mt-4'>Send Email</button>
                  )
                }
                <p className='mt-4'>Remember password? <Link to="/signin" className='text-dark'>Sign In</Link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  )
}

export default ForgotPassword