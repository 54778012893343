import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAddData from '../../../hooks/useAddData';

const DeleteBanner = ({ open, setOpen, data }) => {
    const { setDataToServer } = useAddData('/banner_delete', () => setOpen(false), 'banner_listing')
    const [value, setValue] = useState('')
    const [updatesData, setUpdatesData] = useState(data)
    const handleClose = () => {
        setOpen(false);
    };

    const deleteUpdate = (e) => {
        e.preventDefault();
        if (value === "Delete") {
            alert("delete")
        } else {
            alert("please enter properly")
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setDataToServer.mutate(updatesData)
    }
    return (
        <Dialog open={open} onClose={handleClose} sx={{ minWidth: '25%' }}>
            <form onSubmit={handleFormSubmit}>
                <DialogContent>
                    <h1 className='font-bold text-lg mb-3'>Delete</h1>
                    <p>This action cannot be undone. This will permanently delete this banner</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            color: "gray",
                        }} onClick={handleClose}
                    >Cancel</Button>
                    <Button
                        sx={{
                            color: "#fe100e",
                            ':hover': {
                                bgcolor: "#ffebea"
                            }
                        }}
                        type="submit"
                    >Delete</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default DeleteBanner