import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { useParams, useNavigate, Link, createSearchParams, useLocation } from 'react-router-dom'
import Layout from '../../../components/Layout';
import useGetData from '../../../hooks/useGetData';
import useAddData from '../../../hooks/useAddData';
import AddUpdates from './AddUpdates';
import DeleteUpdate from './DeleteUpdate';
import EditUpdates from './EditUpdates';
import StockChart from '../../../components/StockChart/StockChart';

const getDocumentTypeColor = (rating) => {
  if (rating === "Deleted") {
    return "bg-red-100 text-red-500 border border-red-500"
  } else if (rating === "Active") {
    return "bg-green-100 text-green-500 border border-green-500"
  } else if (rating === "Hidden") {
    return "bg-slate-100 text-slate-500 border border-slate-500"
  }
}

const getRatingColor = (rating) => {
  if (rating === "Exit") {
    return "font-bold text-red-500"
  } else if (rating === "") {
    return "font-bold text-green-500"
  } else if (rating === "Hold") {
    return "font-bold text-blue-500"
  }
}


const CompanyProfile = () => {
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const [isOpenAddUpdatesModal, setIsOpenAddUpdatesModal] = useState(false);
  const [isOpenEditUpdatesModal, setIsOpenEditUpdatesModal] = useState(false);
  const [isOpenDeleteUpdateModal, setIsOpenDeleteUpdateModal] = useState(false);
  const [editUpdatesData, setEditUpdatesData] = useState(null);
  const [deleteUpdateData, setDeleteUpdateData] = useState(null);

  const deleteUpdates = (data) => {
    setDeleteUpdateData(data);
    setIsOpenDeleteUpdateModal(true);
  }

  const editUpdates = (data) => {
    setEditUpdatesData(data);
    setIsOpenEditUpdatesModal(true);
  }

  const deleteCompany = () => {
    setDataToServer.mutate({
      id: params.companyId
    })
  }

  const { handleGetDataList: companyData } = useGetData({ id: params.companyId }, 'getCompanyDetails')
  const { handleGetDataList: updatesList } = useGetData({ id: params.companyId }, 'getCompanyUploadList')
  const { handleGetDataList: companyHistoricalData } = useGetData({ company_id: params.companyId }, 'getHistoricalData')
  const { setDataToServer, error, onError, success, onSuccess } = useAddData('deleteCompany', () => navigate('/company-master'), 'getCompanyList')

  const formattedText = companyData?.data?.data?.[0].company_description?.replace(/\r\n|\r|\n/g, '<br>')
  return (
    <>
      <Layout>
        <div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate('/company-master')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
          </svg>
          <p className='ml-1'>Company Master</p>
        </div>
        <div className='flex items-center mb-4 justify-between md:justify-start'>
          <div className='flex items-center'>
            <h1 className='text-2xl font-bold mr-4'>{companyData?.data?.data?.[0].company_name}</h1>
            <Link to={`/company-master/${params.companyId}/edit`} >
              <p className='py-1 px-3 bg-blue-200 text-blue-600 text-sm font-semibold ml-2 rounded-full'>Edit</p>
            </Link>
            <p className='py-1 px-3 bg-red-200 text-red-600 text-sm font-semibold ml-2 rounded-full hover:cursor-pointer' onClick={deleteCompany}>Delete</p>
          </div>
          <div className='flex items-baseline'>
            <h1 className='text-2xl lg:text-3xl font-bold md:ml-6'>₹ {companyData?.data?.data?.[0]?.current_detail.current_price}</h1>
            {/* {
              parseInt(companyData?.data?.data?.[0]?.current_detail.gain_and_loss) > 0 ? (
                <div className="w-0 h-0 ml-2
                border-l-[4px] border-l-transparent
                -[8px] border-b-green-500
                border-r-[4px] border-r-transparent
                 "></div>
              ):(
                <div className="w-0 h-0 ml-2
                border-l-[4px] border-l-transparent
                border-t-[8px] border-t-red-500
                border-r-[4px] border-r-transparent
                 "></div>
              )

            } */}
            {/* <h3 className={`text-sm ml-1 ${parseInt(companyData?.data?.data?.[0]?.current_detail.gain_and_loss) > 0 ? 'text-green-500':'text-red-500'}`}>{companyData?.data?.data?.[0]?.current_detail.gain_and_loss}</h3> */}
          </div>
        </div>
        <div className='flex justify-start mb-4'>
          {/* <p>{companyData?.data?.data?.[0].company_description}</p>  */}
          <div dangerouslySetInnerHTML={{ __html: formattedText }} />
        </div>
        {/* <div className='w-full h-64 my-8 '>
          {
            companyHistoricalData?.isFetched ? (
              <StockChart data={companyHistoricalData?.data?.data} />
            ) : (
              <p>loading...</p>
            )
          }
        </div> */}
        <div className='grid overflow-hidden grid-cols-1 lg:grid-cols-2  mb-8 rounded border shadow'>
          <div className='flex justify-between rounded-sm md: px-4 bg-[#f9f9f9] md:bg-white'>
            <p className='text-md font-bold py-3 px-4'>BSE Code</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].bse_code}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4'>
            <p className='text-md font-bold py-3 px-4'>NSE Code</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].nse_code}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4 bg-gray-100'>
            <p className='text-md font-bold py-3 px-4'>Avg. Buy Price</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].average_buy_price}</p>
          </div>
          {/* <div className='flex justify-between rounded-sm md: px-4'>
            <p className='text-md font-bold py-3 px-4'>Industry</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].industry}</p>
          </div> */}
          <div className='flex justify-between rounded-sm md: px-4 md:bg-gray-100'>
            <p className='text-md font-bold py-3 px-4'>Short Code</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].short_code}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4 bg-[#f9f9f9] md:bg-white'>
            <p className='text-md font-bold py-3 px-4'>Sizing Guide</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].sizing_guide}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4 '>
            <p className='text-md font-bold py-3 px-4'>Current Market Cap.</p>
            <p className='text-right py-3 px-4'>{parseInt(companyData?.data?.data?.[0].current_detail?.market_cap).toLocaleString("en-US")}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4 bg-gray-100'>
            <p className='text-md font-bold py-3 px-4'>Industry</p>
            <p className='text-right py-3 px-4'>{companyData?.data?.data?.[0].category[0].label}</p>
          </div>
          <div className='flex justify-between rounded-sm md: px-4 md:bg-gray-100'>
            <p className='text-md font-bold py-3 px-4'>Rating</p>
            <p className={`${getRatingColor(companyData?.data?.data?.[0].rating)} rounded text-right py-3 px-4`}>{companyData?.data?.data?.[0].rating}</p>
          </div>
          {
            companyData?.data?.data?.[0].company_link && (
              <div className='flex justify-between rounded-sm md: px-4 bg-[#f9f9f9] md:bg-white'>
                <p className='text-md font-bold py-3 px-4'>Company Link</p>
                <a href={companyData?.data?.data?.[0].company_link} className='text-right py-3 px-4 text-blue-600 hover:underline'>{companyData?.data?.data?.[0].company_link}</a>
              </div>
            )
          }
        </div>
        <div className='flex items-center mb-4  border rounded bg-[#f9f9f9]  shadow'>
          <div className='flex p-4 items-center justify-between md:justify-start'>
            <h1 className='font-bold text-lg'>Initial Report</h1>
            <Link to={{
              pathname: `/company-master/${params.companyId}/update/${companyData?.data?.data?.[0]?.document_id}`,
            }}
            >
              <p className='py-1 px-3 bg-blue-100 border border-blue-500 text-blue-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>View</p>
            </Link>
          </div>

        </div>
        <div className='flex flex-col'>
          <div className='flex items-center mb-4 justify-between'>
            <h1 className='font-bold text-lg'>Updates</h1>
            <p onClick={() => navigate(location.pathname + '/updates/add')} className='h-max w-max rounded-md px-3 py-2 text-dark  border-dark hover:cursor-pointer'>Add</p>
          </div>
          {
            updatesList?.data?.data?.length !== 0 ? (
              updatesList?.data?.data?.map((it) => {
                return (
                  <div key={it.id} className='flex items-center px-4 py-3 border rounded-md mb-3 bg-[#f9f9f9] shadow justify-between'>
                    <div className='flex items-center'>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-4">
                        <path fillRule="evenodd" d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm2.25 8.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm0 3a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" clipRule="evenodd" />
                      </svg> */}
                      <Link to={{
                        pathname: `/company-master/${params.companyId}/update/${it.id}`,
                      }} className='text-md hover:underline underline-offset-1 hover:cursor-pointer font-semibold'>
                        {it.document_name}
                      </Link>
                    </div>
                    <div className='flex items-center'>
                      {
                        it.share ? (
                          <p className='py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Shareable</p>
                        ) : (
                          <p className='py-1 px-3 bg-gray-100 border border-gray-500 text-gray-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Not Shareable</p>
                        )
                      }
                      {
                        it.download ? (
                          <p className='py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Downloadable</p>
                        ) : (
                          <p className='py-1 px-3 bg-gray-100 border border-gray-500 text-gray-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Not Downloadable</p>
                        )
                      }
                      <p className={`text-xs px-3 py-1 rounded-full ml-4 ${getDocumentTypeColor(it.document_type)}`}>{it.document_type}</p>
                      <p className='text-xs ml-4 flex'>({it.date})</p>
                      <svg onClick={() => navigate(location.pathname + '/updates/edit/' + it.id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer ">
                        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                      </svg>
                      <svg onClick={() => deleteUpdates(it)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer text-red-600">
                        <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className='flex justify-center items-center min-h-[15rem] rounded'>
                <p className="text-black-400">No updates found...</p>
              </div>
            )
          }

        </div>
      </Layout>
      <Toaster position="top-right" />
      {/* {isOpenAddUpdatesModal && <AddUpdates open={isOpenAddUpdatesModal} setOpen={setIsOpenAddUpdatesModal} companyId={companyData?.data?.data[0].company_id} />}
      {isOpenEditUpdatesModal && <EditUpdates open={isOpenEditUpdatesModal} setOpen={setIsOpenEditUpdatesModal} data={editUpdatesData} />} */}
      {isOpenDeleteUpdateModal && <DeleteUpdate open={isOpenDeleteUpdateModal} setOpen={setIsOpenDeleteUpdateModal} data={deleteUpdateData} />}

    </>

  )
}

export default CompanyProfile
