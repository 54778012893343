import React, { useRef, useState } from 'react'
import jwtDecode from 'jwt-decode';
import axios, { ReCAPTCHA_KEY } from '../../api/axios'
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from 'react-router-dom';
import FormElement from '../../components/Form/FormElement';
import { SIGNIN_DETAILS } from './authInputs';
import Cookies from 'universal-cookie';
import ReCAPTCHA from 'react-google-recaptcha'
import HeroPage from './HeroPage';

const userdata = {
  email: '',
  password: '',
  reToken: null
}

const cookies = new Cookies;
const SignIn = () => {
  let token = cookies.get("_pn_token");
  const recaptchaRef = useRef(null);
  const navigate = useNavigate()
  const [userData, setUserData] = useState(userdata);
  const [error, setError] = useState('');

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...userData,
      [e.target.name]: value
    }
    setUserData(data)

  }

  const handleAuth = useMutation((data) => handleFormSubmit(data), {
    onSuccess: (data) => {
      cookies.set("_pn_token", data.data.token, { path: '/' })
      cookies.set("_role", data.data.role, { path: '/' })
      cookies.set("_fname", data.data.fname, { path: '/' })
      cookies.set("_subscription", JSON.stringify(data.data.subscription), { path: '/' })
      cookies.set("_name", data.data.name)
      if (data.data.role === "admin") {
        navigate("/company-master", { replace: true });
      } else if (data.data.role === "members") {
        navigate("/", { replace: true });
      }
    },
    onError: (err) => {
      setError(err?.response?.data?.message);
      recaptchaRef.current.reset()
    }
  })

  const handleFormSubmit = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    const response = await axios({
      method: 'POST',
      url: '/login',
      data: fd,
      // withCredentials: true, 
    })
    return response
  }

  const handleAuthSubmit = (e) => {
    e.preventDefault()
    if(!userData?.reToken){
      alert("Please solve the ReCAPTCHA")
      return
    }
    handleAuth.mutate(userData)
  }

  if (token) {
    let decoded = jwtDecode(token);
    if (decoded.role === 'admin') {
      return navigate("/company-master", { replace: true });
    } else {
      return navigate("/stocks", { replace: true });
    }
  }

  return (
    <div className='h-max lg:h-screen w-screen flex flex-col md:flex-row'>
      <div className='flex flex-col w-full h-max lg:flex-row lg:w-[75vw] lg:h-[70vh] lg:m-auto lg:border lg:border-solid lg:rounded-lg lg:shadow-lg'>
        <HeroPage />
        <div className='w-full lg:w-1/2 h-max px-4 md:px-12 m-auto py-12 lg:pt-0 md:py-0 md:my-auto'>
          <form onSubmit={handleAuthSubmit} autoComplete='off'>
            <div className='grid grid-cols-1 auto-rows-auto gap-3 gap-x-6'>
              {/* <h1 className='font-bold text-2xl mb-2'>Sign In</h1> */}
              {error &&
                <p className='text-red-700'>* {error}</p>
              }
              {
                SIGNIN_DETAILS.map((input, idx) => {
                  const { name } = input;
                  return <FormElement
                    key={idx}
                    {...input}
                    value={userData[name]}
                    onChange={handleSetFormData}
                  />
                })
              }
              <ReCAPTCHA
                sitekey={ReCAPTCHA_KEY}
                ref={recaptchaRef}
                onChange={(data) => setUserData({ ...userData, reToken: data })}
              />
              <p className='mt-4 lg:mt-0 text-sm'><Link to="/forgot-password" className='text-dark hover:underline'>Forgot Password?</Link></p>
              {
                handleAuth.isLoading ? (
                  <button type='submit' className='flex items-center justify-center bg-light text-white py-2 rounded-md mt-4 lg:mt-2'>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Signing In...
                  </button>
                ) : (
                  <button type='submit' className='bg-light text-white py-2 rounded-md mt-4 lg:mt-2'>Sign In</button>
                )
              }
              <p className='mt-2 text-sm'>Don't have an account? <Link to="/signup" className='text-dark border-dark pt-1 px-3 pb-1  rounded-md text-[15px] font-semibold'>Sign Up Now</Link></p>
            </div>
          </form>
        </div>

      </div>
    </div>
  )
}

export default SignIn